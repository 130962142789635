import React, { useEffect, useState, useRef } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { HttpClient } from "@utilities/http-client";

import Layout from '@components/domain/shared/layout';
import Description from '@components/domain/course-details/description';

import SEO from "@components/ui/seo";
import EmptySpace from '@components/ui/empty-space';
import Spacing from '@components/ui/spacing';

import { months } from "@utilities/course-data-helpers";

import styles from '@styles/templates/certificado.module.scss';

import ActionLink from '@components/ui/action-link';

const Certificate = (req) => {
    const certificate_id = req.params.certificate_id;
    const ref = useRef(true);

    const [participantCert, setParticipantCert] = useState();

    const breakpoints = useBreakpoint();

    const httpClient = HttpClient(null);

    // load course
    useEffect(() => {
        (async () => {
            // Only call backend once
            if (!ref.current) return;
            ref.current = false;

            document.body.classList.add(styles.certificatebody);

            const response = await httpClient(`participants/certificate/${certificate_id}`);
            if (response.ok) {
                let res = await response.json();
                setParticipantCert(() => {
                    let start_date = new Date(res.course_iteration.start_date);
                    let end_date = new Date(res.course_iteration.end_date);

                    let start_month = months[start_date.getMonth()];
                    let start_year = start_date.getFullYear();
                    let end_month = months[end_date.getMonth()];
                    let end_year = end_date.getFullYear();

                    // Meter os roles com primeira letra em maiuscula! :D
                    let roles = res.cert_roles.map(role => role[0].toUpperCase() + role.substr(1))

                    let roles_phrase_start = "";
                    let roles_phrase_end = "";

                    if (roles.includes("aluno")) {
                        roles_phrase_start += "tendo assim direito a receber este certificado.";
                        roles_phrase_end = "";
                    } else {
                        let roles_str = roles[roles.length - 1];
                        let sep = " e ";
                        for (let i = roles.length - 2; i >= 0; i--) {
                            roles_str = roles[i] + sep + roles_str;
                            sep = ", ";
                        }
                        roles_phrase_start = "tendo desempenhado " + ((roles.length >= 2) ? "as funções" : "a função") + " de ";
                        roles_phrase_end = roles_str;
                    }

                    return {
                        ...res,
                        start_month: start_month,
                        start_year: start_year,
                        end_month: end_month,
                        end_year: end_year,
                        roles_phrase_start: roles_phrase_start,
                        roles_phrase_end: roles_phrase_end
                    };
                });
            }
        })();
    });

    return (
        <span>
            <Layout isFooterHidden={true} isNavLinksHidden={true} extraFooterPadding={breakpoints.mobile ? 200 : 0}>
                <SEO title="Certificado After School" />
                <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />

                <div className={styles.container}>
                    <EmptySpace desktop={{ margin: 30 }} mobile={{ margin: 20 }} />

                    {participantCert &&
                        <Spacing>
                            <EmptySpace desktop={{ margin: 30 }} mobile={{ margin: 20 }} />
                            <section className={styles.overview}>
                                <h1>
                                    Certificado<br/>
                                    {participantCert.name}
                                </h1>
                                <p>
                                    Certifica-se que {participantCert.name} participou no&nbsp;<span className={styles.course_title}>After School - {participantCert.course_iteration.course.name}, edição {participantCert.course_iteration.iteration_name}
                                    </span>, do TreeTree2, organizado com o apoio do Instituto Superior Técnico, da Sociedade Portuguesa de Física, da Sociedade Portuguesa de Matemática e do Instituto Português do Desporto e da Juventude, de {participantCert.start_month} de {participantCert.start_year} a {participantCert.end_month} de {participantCert.end_year}, {participantCert.roles_phrase_start}
                                </p>
                                {participantCert.roles_phrase_end != "" &&
                                    <span className={styles.roles}>
                                        {participantCert.roles_phrase_end}
                                    </span>
                                }
                                
                                <EmptySpace desktop={{ margin: 40 }} mobile={{ margin: 30 }} />
                                <ActionLink type="dark" width="275px" path="/" label="Descobre mais sobre o After School" />
                            </section>
                        </Spacing>
                    }
                    {!participantCert &&
                        <Spacing>
                            <div className={styles.metadata}>
                                <section className={styles.overview}>
                                    <Description
                                        title="O certificado que procuras não existe."
                                    />
                                </section>
                            </div>
                        </Spacing>
                    }
                </div>
                <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
            </Layout>
        </span>
    );
}

export default Certificate;